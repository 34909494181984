import { StreamActions } from "@hotwired/turbo";
import { FetchRequest } from "@rails/request.js";

const initializeTurboFrameEvent = () => {
  const turboFrameEvent = new Event("turbo:frame-loaded");

  const observer = new MutationObserver(() => {
    document.dispatchEvent(turboFrameEvent);
  });

  const targetNodes = document.querySelectorAll("turbo-frame");
  const observerOptions = {
    childList: true,
    attributes: false,
    subtree: true,
  };
  targetNodes.forEach((targetNode) => observer.observe(targetNode, observerOptions));
};

window.addEventListener("load", () => {
  initializeTurboFrameEvent();
});

document.addEventListener("turbo:load", () => {
  initializeTurboFrameEvent();
});

StreamActions.request_submit = function requestSubmit() {
  const formToSubmit = document.getElementById(this.getAttribute("id"));

  if (formToSubmit && typeof formToSubmit.requestSubmit === "function") {
    formToSubmit.requestSubmit();
  }
};

StreamActions.request_stream = function requestStream() {
  new FetchRequest("get", this.getAttribute("id"), {
    responseKind: "turbo-stream",
  }).perform();
};
