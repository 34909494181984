import "core-js/stable"
import "regenerator-runtime/runtime"
import "controllers"

import Rails from "@rails/ujs";
import { FetchRequest } from "@rails/request.js";
import "./turbo_load";
import "@hotwired/turbo-rails";
import * as Turbo from "@hotwired/turbo";
import TurboPower from "turbo_power";

Turbo.session.drive = false
TurboPower.initialize(Turbo.StreamActions);
window.Rails = Rails;
window.FetchRequest = FetchRequest;
Rails.start();

require("trix")
require("@rails/actiontext")

// require("popper.js")
// require("bootstrap")
