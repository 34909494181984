// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'

if (typeof CURRENT_USER_ID !== "undefined") {
    // eslint-disable-next-line global-require
    import('../channels');
}

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.consumer = consumer;
StimulusReflex.initialize(application, { consumer, controller, debug: false })

// import Flatpickr
import Flatpickr from "stimulus-flatpickr"
// Import style for flatpickr
// require("flatpickr/dist/flatpickr.css")
// Manually register Flatpickr as a stimulus controller
application.register("flatpickr", Flatpickr)
